<template lang="pug">
section.container.section.email-invalid
  .columns
    .column.is-8.is-offset-2
      h1.title Verify your email address
  .columns.mt-2
    .column.box.is-8.is-offset-2
      b-message(title="There's a problem with your email address" type="is-warning" :closable="false" v-if="!showSecondMessage")
        .content
          p
            | Unfortunately the email address on your account is not accepting emails.
            | Please double check the spelling of your email address below and update
            | it if needed.
          p
            | To continue using nutworth, you will need to successfully verify your
            | email address. If you need help,&nbsp;
            a(href="mailto:support@nutworth.com" target="_blank") contact us
            | .
      b-message(title="Click the verification link" type="is-info" :closable="false" v-if="showSecondMessage")
        .content
          p
            | Almost done! Check your inbox for a verification link from nutworth
            | & click it. If you need help,&nbsp;
            a(href="mailto:support@nutworth.com" target="_blank") contact us
            | .
      b-field(label="Email Address")
        b-input(v-model="newEmail" type="email")
      b-button.is-primary(:disabled="emailInvalid" :loading="isProcessing" @click="submit") Send Verification
</template>

<script>
import firebase from 'firebase/app'
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    isProcessing: false,
    showSecondMessage: false,
    newEmail: ''
  }),
  computed: {
    ...mapState('authentication', ['user']),
    emailInvalid() {
      const { newEmail } = this
      if (!newEmail || !newEmail.length) {
        return true
      }
      if (!newEmail.match(/[^@]@[^@]/)) {
        return true
      }
      return false
    }
  },
  watch: {
    'user.email': {
      handler(email) {
        if (email) {
          this.newEmail = email.slice()
        }
      },
      immediate: true
    },
    'user.emailInvalid': {
      handler(invalid) {
        if (this.user && !invalid) {
          this.$router.push('/portfolio/data').catch(() => {})
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('authentication', [
      'changeEmailAddress',
      'sendEmailVerification',
      'onEmailVerified'
    ]),
    submit() {
      if (this.isProcessing) {
        return
      }

      this.isProcessing = true
      let promise = null

      if (this.newEmail === this.user.email) {
        promise = this.sendEmailVerification()
      } else {
        promise = this.changeEmail()
      }
      promise
        .then(status => {
          // If it's come back as already verified, I guess we've got a
          // bugged verification state. Fix it now
          if (status === 'already-verified') {
            return this.onEmailVerified().then(() => {
              this.$buefy.toast.open('Your account has been verified. Thanks!')
            })
          }
          this.showSecondMessage = true
          return undefined
        })
        .catch(error => {
          this.$buefy.toast.open(`Error: ${error.message}`)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    changeEmail(event, retry = false) {
      let message = 'Enter your password to proceed.'
      if (retry) message = 'Invalid password. Please try again.'
      this.changingEmail = true
      return this.reauthenticate({
        title: 'Changing Email Address',
        message,
        type: 'is-info',
        hasIcon: true,
        confirmText: 'Confirm Change'
      })
        .catch(error => {
          if (error.message === 'invalid-password') {
            return this.changeEmail(event, true)
          }
          throw error
        })
        .then(() => this.changeEmailAddress(this.newEmail.trim()))
        .catch(error => {
          if (error.code === 'auth/email-already-in-use') {
            this.newEmail = this.user.email
            throw new Error('Email already in use.')
          }
          throw error
        })
    },

    reauthenticate(options) {
      return new Promise((resolve, reject) => {
        this.$buefy.dialog.prompt({
          ...options,
          inputAttrs: {
            placeholder: 'Password',
            type: 'password',
            autocomplete: 'current-password'
          },
          onConfirm: password => {
            const credential = firebase.auth.EmailAuthProvider.credential(
              this.user.email,
              password
            )
            firebase
              .auth()
              .currentUser.reauthenticateWithCredential(credential)
              .then(
                () => resolve(),
                () => reject(new Error('invalid-password'))
              )
          },
          onCancel: () => reject(new Error('cancel'))
        })
      })
    }
  },
  metaInfo: {
    title: 'Update your Email Address'
  }
}
</script>
